import { ParcoursUrlUtils } from '@effy-tech/acquisition-core';

export const LANDING_SOLAR_PATH = 'nos-offres/solaire';

export enum LandingSolarPageSelector {
  Page1 = 'page-1',
  Page2 = 'page-2',
  Page3 = 'page-3',
  Page4 = 'page-4',
  Page5 = 'page-5',
  Page6 = 'page-6',
}

export interface Meta {
  name: string;
  content: string;
}

export interface LandingSolarLang {
  title: string;
  meta: Meta;
}

export const LANDING_SOLAR_LANG: LandingSolarLang = {
  title: ' Le solaire, votre énergie au quotidien | Effy',
  meta: {
    name: 'description',
    content: `Installez des panneaux solaires avec Effy et économisez jusqu'à 1 400€ par an en produisant votre propre électricité.`,
  },
};

export const FORM_SOLAR_URL = ParcoursUrlUtils.computeSolaire();

export const LANDING_SOLAR_CHILD_PAGES = {
  FormSolar: FORM_SOLAR_URL,
  Caracteristics: `/${LANDING_SOLAR_PATH}/caracteristiques-techniques`,
  InstallationWithEffy: `/${LANDING_SOLAR_PATH}/installation-avec-effy`,
  HowItWorks: `/${LANDING_SOLAR_PATH}/comment-ca-marche`,
};

export const allPagesInOrder = Object.values(LandingSolarPageSelector);